/* eslint-disable react/no-unstable-nested-components */
//
import React, {
  useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { atom, useSetRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';
import {
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'gatsby';
import { DotPulse } from '@uiball/loaders';
import { Mobile, PC } from '../../components/layout/mediaQuery';
import '../../styles/colors.scss';
import { balance } from '../../contents/balance-array';

import { KAKAO_CS_URL, MEALION_URL } from '../../constants/urls';
import twitterLogo from '../../assets/images/product/twitter.png';
import facebookLogo from '../../assets/images/product/facebook.png';
import linkLogo from '../../assets/images/product/link.png';
import SvgLogoText from '../../assets/images/brand/logo-text';
import Kakao from '../../Kakao';
import line from '../../assets/images/icon/line.png';
import NotFoundPage from '../404';
import { Share } from './what-to-eat';
import { CarouselProvider, Carousel } from 'pure-react-carousel';

interface isMobileProp {
  m: boolean,
}
interface containerProp {
  pageNum: number,
}
interface progressProp {
  m: boolean,
  pageNum: number;
}
interface questionProp {
  m: boolean,
  blue: boolean,
}
interface retryButtonProp {
  m: boolean,
  retry: boolean,
}
export const IsGame = atom({
  key: 'IsGame',
  default: false,
});

function Game() {
  const thisUrl = `${MEALION_URL}/game/balance`;

  const setIsGame = useSetRecoilState(IsGame);
  useEffect(() => {
    setIsGame(true);
  }, []);

  function shuffle(array: any[]) {
    return array.sort(() => Math.random() - 0.5);
  }

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [pageNum, setPageNum] = useState(0);
  const [thumbnail, setThumbnail] = useState('http://mealion.kr/static/screenshot-41a496b760f3592a17f71936b325120f.png');
  const [shuffledBalance, setShuffledBalance] = useState(shuffle(balance));
  const [index, setIndex] = useState<number>(0);
  const [round, setRound] = useState<number>(0);
  const [result, setResult] = useState<any[]>();

  function pageNumPlus(id: string) {
    const nextPage = pageNum + 1;
    setPageNum(nextPage);
  }

  function onClick(e: React.MouseEvent<HTMLButtonElement>) {
    console.log(shuffledBalance);

    const { id } = e.currentTarget;
    pageNumPlus(id);
    const numId = parseInt(id, 10);
    console.log('인덱스', index, (16 / (2 ** (round)) - 2));
    if (pageNum > 0 && index < (16 / (2 ** (round)) - 2)) {
      console.log('numId', numId);
      const temp = [...shuffledBalance];
      temp.forEach((item) => {
        if (item.id === numId) {
          const arrayIndex = temp.indexOf(item);
          temp[arrayIndex] = { ...temp[arrayIndex], score: temp[arrayIndex].score + 1 };
          setShuffledBalance(temp);
        }
      });
      setIndex(index + 2);
    } else if (index === (16 / (2 ** (round)) - 2)) {
      console.log('마지막');
      console.log(round);
      setRound(round + 1);
      setIndex(0);
      const temp: any[] = [];
      shuffledBalance.forEach((item: any) => { // balance
        if (item.score === round + 1) {
          temp.push(item);
        }
        if (item.id === numId) {
          temp.push({
            id: item.id,
            text: item.text,
            score: item.score + 1,
          });
        }
      });
      setShuffledBalance(temp);
      console.log('갱신된 shuffledBalance:', temp);

      if (round < 2) {
        window.alert(`${round + 2}라운드 시작`);
      } else if (round === 2) {
        window.alert('결승 시작');
      } else if (round === 3) {
        setResult(temp);
      }
    }
  }

  function Button() {
    console.log('index', index, shuffledBalance);
    const buttons = shuffledBalance.slice(index, index + 2);
    // setIndex(index + 1);
    return (
      <>
        {buttons.map((item: any) => (
          <ButtonInActive
            key={item.id}
            type="button"
            id={item.id.toString()}
            onClick={onClick}
            m={isMobile}
          >
            {item.text}
          </ButtonInActive>
        ))}
      </>
    );
  }

  function Page() {
    return (
      <div style={{ height: '100vh', marginTop: isMobile ? 'calc(0.266vw * 50)' : 'calc(0.052vw * 50)' }}>
        {/* <ProgressText m={isMobile}>
          {pageNum}
          /5
        </ProgressText>
        <ProgressBar m={isMobile}>
          <Progress pageNum={pageNum} m={isMobile} />
        </ProgressBar> */}

        <ButtonContainer m={isMobile}>
          <Button />
        </ButtonContainer>
      </div>
    );
  }
  useEffect(() => {
    console.log('round', round);
  }, [round]);
  // 다시하기
  function Reset() {
    window.location.reload();
    // console.log(balance.length, shuffle(balance).length);
    // setShuffledBalance(shuffle(balance));
    // setPageNum(0);
  }
  function Content() {
    return (
      <div style={{
        // height: pageNum !== 6 && '100vh',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--color-light)',
      }}
      >
        <Center>
          <Container pageNum={pageNum} m={isMobile}>
            {pageNum === 0 && <StartPage />}
            {(round >= 0 && round <= 3) && (
              <Page />
            )}
            {/* {pageNum === 9 && <LoadingPage />} */}
            {round === 4 && <ResultPage />}
          </Container>
        </Center>
      </div>
    );
  }

  function StartPage() { // 시작 페이지
    return (
    // <NotFoundPage />
      <div style={{ height: '100vh' }}>
        <Question blue={false} m={isMobile} style={{ marginTop: 'calc(0.266vw * 90)' }}>
          이상형 게임
        </Question>
        <ButtonContainer m={isMobile} style={{ marginTop: '38px' }}>
          <Next m={isMobile} onClick={onClick}>시작하기</Next>
        </ButtonContainer>
      </div>
    );
  }
  function LoadingPage() {
    setTimeout(() => {
      setPageNum(pageNum + 1);
    }, 3000);
    return (
      <Center>
        <Question m={isMobile} blue={false} />
        <DotPulse color="var(--color-new-primary)" />
      </Center>
    );
  }

  // 결과창
  function ResultPage() {
    return (
      <div>
        {result[0].text}
        {/* <ColumnFlex>
          <RetryButton retry m={isMobile} onClick={Reset} style={{ marginTop: isMobile ? 'calc(0.266vw * 106)' : 'calc(0.052vw * 106)' }}>다시하기</RetryButton>
          <a href={MEALION_URL} target="_blank" rel="noopener noreferrer" style={{ width: '100%' }}>
            <RetryButton retry={false} m={isMobile}>밀리언 홈페이지 보러가기</RetryButton>
          </a>
          <ResultTextShare m={isMobile}>
            게임 공유하기
          </ResultTextShare>
          <Share thisUrl={thisUrl} isMobile={isMobile} thumbnail="" game="what-to-eat" />
          <img src={line} alt="line" width="196px" />
        </ColumnFlex>
        <RowFlex2 m={isMobile}>
          <TextWithLogo m={isMobile}>
            맛있는 발견이 필요할 때
          </TextWithLogo>
          <span style={{ marginLeft: isMobile ? 'calc(0.266vw *6)' : 'calc(0.052vw * 6)', width: isMobile ? 'calc(0.266vw * 79)' : 'calc(0.052vw * 79)' }}>
            <SvgLogoText fill="#369FFF" width="100%" />
          </span>
        </RowFlex2> */}
        <div className="result_text_title">오늘 뭐 먹지?</div>
        <div className="result_text_sub">
          매일 반복되는 메뉴 고민
          <br />
          선택이 “쉬워지는” 밀리언
        </div>

        <div className="gray_background">
          {isMobile
            ? (
              <CarouselProvider naturalSlideHeight={10} naturalSlideWidth={125} totalSlides={4}>
                <Carousel />
              </CarouselProvider>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <img className="slide_mockup" src={resultSlide1} width="100%" alt="mockup1" />
                  <div className="slide_mockup_text">
                    뭐먹지?
                    <span className="slide_mockup_text_strong">
                      &nbsp;고민
                      <span className="marker" />
                    </span>
                    이 쉬워진다.
                  </div>
                </div>
                <div>
                  <img className="slide_mockup" src={resultSlide2} width="100%" alt="mockup2" />
                  <div className="slide_mockup_text">
                    뭐먹지?
                    <span className="slide_mockup_text_strong">
                      &nbsp;발견
                      <span className="marker" />
                    </span>
                    이 쉬워진다.
                  </div>
                </div>
                <div>
                  <img className="slide_mockup" src={resultSlide3} width="100%" alt="mockup3" />
                  <div className="slide_mockup_text">
                    뭐먹지?
                    <span className="slide_mockup_text_strong">
                      &nbsp;저장
                      <span className="marker" />
                    </span>
                    이 쉬워진다.
                  </div>
                </div>
                <div>
                  <img className="slide_mockup" src={resultSlide4} width="100%" alt="mockup4" />
                  <div className="slide_mockup_text">
                    뭐먹지?
                    <span className="slide_mockup_text_strong">
                      &nbsp;공유
                      <span className="marker" />
                    </span>
                    이 쉬워진다.
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    );
  }

  // 결과창
  return (
    <>
      <Helmet>
        <title>오늘 뭐 먹지? 🤔</title>
        <meta name="description" content="밀리언이 추천해줄게!" data-react-helmet="true" />
        <meta property="og:title" content="오늘 뭐 먹지? 🤔" />
        <meta property="og:description" content="밀리언이 추천해줄게!" />
        <meta property="og:url" content="https://mealion.kr/game/what-to-eat" />
        <meta property="og:image" content="https://mealion.kr/static/game-bc6a095b844abcd9416babc23577acc8.png" />
      </Helmet>
      <Mobile>
        <Content />
      </Mobile>
      <PC>
        <div style={{
          width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row',
        }}
        >
          <div style={{ width: '30%', height: '100%' }}>
            <Content />
          </div>
        </div>
      </PC>
    </>
  );
}
export default Game;

const Center = styled.div`
  background: var(--color-light);
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 0;
`;
const Container = styled.div<containerProp>`
  background: var(--color-light);
  text-align: left;
  width: 90%;
  align-items: center;
  overflow: ${(props) => (props.pageNum === 7 ? 'auto' : 'hidden')}; // last page
`;
const ColumnFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const RowFlex = styled(ColumnFlex)<isMobileProp>`
  flex-direction: row;
  gap: ${(props) => (props.m ? 'calc(0.266vw * 12)' : 'calc(0.052vw * 12)')};
`;

const RowFlex2 = styled(RowFlex)`
  gap: ${(props) => (props.m ? 'calc(0.266vw * 6)' : 'calc(0.052vw * 6)')};
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 30)' : 'calc(0.052vw * 30)')};
  /* margin-bottom: ${(props) => (props.m ? 'calc(0.266vw * 30)' : 'calc(0.052vw * 30)')}; */
`;

const ProgressBar = styled.div`
  background: --color-gray-input;
  width: 100%;
  height:  ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  border-radius:  ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
`;

const Progress = styled.div<progressProp>`
  background: var(--color-new-primary);
  width: ${(props) => 20 * (props.pageNum - 1)}%;
  height: 100%;
  border-top-left-radius:  ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  border-bottom-left-radius:  ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
`;

const ProgressText = styled.div<isMobileProp>`
  text-align: right;
  color: var(--color-gray-text);
  font-size:  ${(props) => (props.m ? 'calc(0.266vw * 14)' : 'calc(0.052vw * 14)')};
  line-height:  ${(props) => (props.m ? 'calc(0.266vw * 14)' : 'calc(0.052vw * 14)')};
  font-weight: 400;
  margin-bottom: ${(props) => (props.m ? 'calc(0.266vw * 6)' : 'calc(0.052vw * 6)')};
  margin-right: calc(0.266vw * 7);
`;
const StoryBoard = styled.div<progressProp>`
  font-size:  ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  line-height:  ${(props) => (props.m ? 'calc(0.266vw * 24)' : 'calc(0.052vw * 24)')};
  letter-spacing:  ${(props) => (props.m ? 'calc(0.266vw * -0.5)' : 'calc(0.052vw * -0.5)')};
  /* color: var(--color-gray-text); */
  color: #6D7780;
  /* background: var(--color-skyblue); */
  background: ${(props) => ((props.pageNum === 1) ? '#0080FF1A' : 'var(--color-gray-input)')};
  text-align: left;
  border-radius:  ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  padding:  ${(props) => (props.m ? 'calc(0.266vw * 17)' : 'calc(0.052vw * 17)')};
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 64)' : 'calc(0.052vw * 64)')};
  flex: 1;
  white-space: pre-wrap;
`;

const ButtonContainer = styled.div<isMobileProp>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.m ? 'calc(0.266vw * 15)' : 'calc(0.052vw * 15)')};
`;

const ButtonInActive = styled.button<isMobileProp>`
  width: 100%;
  margin: 0;
  border: none;
  padding: ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  background: var(--color-gray-input);
  border-radius: ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  color: var(--color-black-text);
  font-weight: 500;
  line-height: ${(props) => (props.m ? 'calc(0.266vw * 24)' : 'calc(0.052vw * 24)')};
  letter-spacing: ${(props) => (props.m ? 'calc(0.266vw * -0.33)' : 'calc(0.052vw * -0.33)')};
  text-align: center;
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  /* white-space: pre; */
`;

const Next = styled.button<isMobileProp>`
  position: absolute;
  background: var(--color-temporary-primary);
  border-radius: ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  bottom: ${(props) => (props.m ? 'calc(0.266vw * 60)' : 'calc(0.052vw * 60)')};
  width: ${(props) => (props.m ? '90%' : '27%')};
  height: ${(props) => (props.m ? 'calc(0.266vw * 56)' : 'calc(0.052vw * 56)')};
  border: none;
  font-weight: 700;
  color: var(--color-light);
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 16)' : 'calc(0.052vw * 16)')};
  text-align: center;
  letter-spacing: ${(props) => (props.m ? 'calc(0.266vw * -0.33)' : 'calc(0.052vw * -0.33)')};
`;
const Question = styled.div<questionProp>`
  font-weight: 700;
  text-align: center;
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 18)' : 'calc(0.052vw * 18)')};
  line-height: 130%;
  color: ${(props) => (props.blue ? 'var(--color-new-primary)' : 'var(--color-black-text)')};
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 80)' : 'calc(0.052vw * 80)')};
  margin-bottom: ${(props) => (props.m ? 'calc(0.266vw * 82)' : 'calc(0.052vw * 82)')};; //116
  white-space: pre-wrap;
  text-align: center;
`;

const FoodName = styled.div<isMobileProp>`
  font-weight: 700;
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 24)' : 'calc(0.052vw * 24)')};
  line-height: ${(props) => (props.m ? 'calc(0.266vw * 30)' : 'calc(0.052vw * 30)')};
  text-align: center;
  color: var(--color-new-primary);
`;

const FoodSub = styled.div<isMobileProp>`
  font-weight: 500;
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 18)' : 'calc(0.052vw * 18)')};
  line-height: ${(props) => (props.m ? 'calc(0.266vw * 30)' : 'calc(0.052vw * 30)')};
  text-align: center;
  color: var(--color-black);
`;

const ResultTextShare = styled.div<isMobileProp>`
  color: var(--color-gray-text);
  font-weight: 500;
  font-size:${(props) => (props.m ? 'calc(0.266vw * 14)' : 'calc(0.052vw * 14)')};
  letter-spacing: ${(props) => (props.m ? 'calc(0.266vw * -0.33)' : 'calc(0.052vw * -0.33)')};
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 86)' : 'calc(0.052vw * 86)')};
`;

const GrayBoard = styled(StoryBoard)<isMobileProp>`
  background: var(--color-gray-input);
  height: ${(props) => (props.m ? 'calc(0.266vw * 215)' : 'calc(0.052vw * 215)')};
  padding: ${(props) => (props.m ? 'calc(0.266vw * 15) 0' : 'calc(0.052vw * 15) 0')}; 

`;

const GrayBoardText = styled.div<isMobileProp>`
  color: var(--color-black-text);
  font-weight: 700;
  letter-spacing: ${(props) => (props.m ? 'calc(0.266vw * -0.33)' : 'calc(0.052vw * -0.33)')};
  text-align: center;
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 14)' : 'calc(0.052vw * 14)')};
`;

const GrayBoardTextSub = styled(GrayBoardText)`
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 10)' : 'calc(0.052vw * 10)')};
  font-weight: 500;

`;

const RetryButton = styled(Next)<retryButtonProp>`
  position: relative;
  width: 100%;
  margin: 0;
  bottom: 0;
  background: ${(props) => (props.retry ? 'var(--color-gray-border)' : 'var(--color-new-primary)')};
  color: ${(props) => (props.retry ? 'var(--color-black-text)' : 'var(--color-light)')};
  margin-top: ${(props) => (props.m ? 'calc(0.266vw * 19)' : 'calc(0.052vw * 19)')};
`;

const TextWithLogo = styled.div<isMobileProp>`
  color: var(--color-light-gray-text);
  font-size: ${(props) => (props.m ? 'calc(0.266vw * 14)' : 'calc(0.052vw * 14)')};
  line-height: 100%;
  font-weight: 500;
`;

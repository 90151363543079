export const balance = [
  {
    id: 0,
    text: '쩝쩝쩝쩝.. 먹는 내내 효과음 내고 되새김질하는 애인',
    score: 0,
  },
  {
    id: 1,
    text: '뒤적뒤적… 자꾸 본인 젓가락으로 뒤적거리면서 반찬 투정하는 오지게 하는 애인',
    score: 0,

  },
  {
    id: 2,
    text: '음식 먹을 때 마다 혀마중 장난아니게 나오는데 혓바닥에 고춧가루 붙어있는 애인',
    score: 0,

  },
  {
    id: 3,
    text: '다 씹기 전에 말해서 자꾸 음식물 튀기고 입 안 다 보여주면서 해맑게 웃는 애인',
    score: 0,

  },
  {
    id: 4,
    text: '본인 음식은 공유 안하면서 내 음식만 뺏어 먹고 식탐부리는 애인',
    score: 0,

  },
  {
    id: 5,
    text: '안먹는다고 해서 1인분만 시켰는데 맨날 한 입만 달라고 하는 애인',
    score: 0,

  },
  {
    id: 6,
    text: '먹을 때 마다 밥맛 떨어지는 더럽고 역겨운 소리하고 즐거워하는 애인',
    score: 0,

  },
  {
    id: 7,
    text: '내 의사 묻지도 않고 내 설렁탕에 깍두기 국물 부어버리는 애인',
    score: 0,

  },
  {
    id: 8,
    text: '먹을 때 마다 ‘그거 그렇게 먹는 거 아닌데…’ 아는 척 대박인 애인',
    score: 0,

  },
  {
    id: 9,
    text: '잔뜩 먹고 계산할 때는 항상 신발끈 묶고 딴짓하는 애인',
    score: 0,

  },
  {
    id: 10,
    text: '식당 갈 때마다 락앤락 들고 와서 먹기도 전에 음식 왕창 덜어가는 애인',
    score: 0,

  },
  {
    id: 11,
    text: '초밥집 가서 초밥의 밥은 안먹고 위에 생선만 집어 먹으면서 “이렇게 먹어야 이득이지~ “하는 애인',
    score: 0,

  },
  {
    id: 12,
    text: '뷔페가서 학생증을 두고왔지만 학생이라며 학생 요금 받으라고 바락바락 우기는 애인',
    score: 0,

  },
  {
    id: 13,
    text: '치즈 떡볶이 먹을 때 젓가락이 아닌 손으로 치즈 늘려가면서 손가락에 돌돌 말아먹는 애인',
    score: 0,

  },
  {
    id: 14,
    text: '같이 게장 먹으러 가서 게딱지 지가 다 먹어서 열받은 내가 1인분 추가하니 “자기는 많이 먹네~” 하는 애인',
    score: 0,

  },
  {
    id: 15,
    text: '뭐 먹고 싶어? 하면 ‘아무거나’ 라고 말하지만 내가 제안하는 메뉴 30개 째 거부 중인 애인',
    score: 0,

  },
];
